import { Calendar } from "@material-ui/pickers";
import { MaterialUiPickersDate } from "@material-ui/pickers/typings/date";
import { Dayjs as DayjsType } from "dayjs";
import * as React from "react";

import CustomRangePickerMonth from "~/components/core/datepickers/CustomRangePicker/CustomRangePickerMonth";
import CustomRangePickerYear from "~/components/core/datepickers/CustomRangePicker/CustomRangePickerYear";
import styled from "~/components/core/styled";
import { DATE_UNIT } from "~/constants/dates";
import { RenderDayFunc } from "~/declarations/mui-datepickers";

export interface Props {
  renderDay: RenderDayFunc;
  currentView: DATE_UNIT;
  date: DayjsType;
  month: DayjsType;
  onSelectYear: (date: MaterialUiPickersDate) => void;
  onSelectMonth: (date: MaterialUiPickersDate) => void;
}

const DatePickerContent: React.FC<Props> = ({
  renderDay,
  date,
  month,
  onSelectYear,
  onSelectMonth,
  currentView
}: Props): JSX.Element => {
  if (currentView === DATE_UNIT.year) {
    return (
      <CustomRangePickerYear initialValue={month} onChange={onSelectYear} />
    );
  }

  if (currentView === DATE_UNIT.month) {
    return (
      <CustomRangePickerMonth initialValue={month} onChange={onSelectMonth} />
    );
  }

  return (
    <CalendarWrapper>
      <Calendar
        date={date}
        currentMonth={date}
        renderDay={renderDay}
        slideDirection="left"
        wrapperVariant="static"
        reduceAnimations={false}
        onChange={(): void => void 0}
      />
    </CalendarWrapper>
  );
};

export default DatePickerContent;

const CalendarWrapper = styled.div`
  width: 205px;

  > div:first-of-type {
    > span {
      width: 24px;
      height: 24px;
      margin: 0 1px;
      font-weight: 600;
      color: ${({ theme }): string => theme.palette.common.black};
    }
  }

  > div:last-child {
    min-height: 160px;
  }
`;
