import * as React from "react";

import styled from "~/components/core/styled";
import { DATE_FORMAT } from "~/constants/dates";
import { DateOrNull } from "~/declarations/filters";

export interface Props {
  begin?: DateOrNull;
  end?: DateOrNull;
  withActivationIndication?: boolean;
}

const CustomRangePickerHeader: React.FC<Props> = ({
  begin,
  end,
  withActivationIndication
}: Props): JSX.Element => {
  const formattedBegin = begin?.format(DATE_FORMAT);
  const formattedEnd = end?.format(DATE_FORMAT);
  const endActive = !!begin;

  return (
    <Wrapper>
      <Value
        active={withActivationIndication && !endActive}
        empty={!formattedBegin}
      >
        {formattedBegin || DATE_FORMAT}
      </Value>
      <Separator>-</Separator>
      <Value
        active={withActivationIndication && endActive}
        empty={!formattedEnd}
      >
        {formattedEnd || DATE_FORMAT}
      </Value>
    </Wrapper>
  );
};

export default CustomRangePickerHeader;

interface ValueProps {
  active?: boolean;
  empty: boolean;
}

const Value = styled.div<ValueProps>`
  z-index: 1;
  height: 35px;
  width: 70px;
  padding-top: 6px;
  line-height: 1.6em;
  text-align: center;
  font-size: ${({ empty }): string => (empty ? "14px" : "16px")};
  color: ${({ theme, empty }): string =>
    empty ? theme.palette.grey[400] : theme.palette.common.black}};
  border-bottom-color: ${({ theme, active }): string =>
    active ? theme.palette.primary.main : theme.palette.grey[400]};
  border-bottom-style: solid;
  border-bottom-width: ${({ active }): string => (active ? "2px" : "1px")};
`;

const Wrapper = styled.div`
  display: flex;
  height: 35px;
  justify-content: space-around;
  margin-bottom: 5px;
  position: relative;

  &:after {
    border-bottom: 1px solid ${({ theme }): string => theme.palette.grey[400]};
    content: "";
    height: 35px;
    position: absolute;
    width: 100%;
  }
`;

const Separator = styled.span`
  padding-top: 6px;
`;
