import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import { MaterialUiPickersDate } from "@material-ui/pickers/typings/date";
import dayjs, { Dayjs as DayjsType } from "dayjs";
import * as React from "react";

import CustomRangePickerHeader from "~/components/core/datepickers/common/CustomRangePickerHeader";
import CustomRangePickerDay from "~/components/core/datepickers/CustomRangePicker/CustomRangePickerDay";
import CustomRangePickerVariants from "~/components/core/datepickers/CustomRangePicker/CustomRangePickerVariants";
import DatePicker from "~/components/core/datepickers/DatePicker";
import styled from "~/components/core/styled";
import { DATE_UNIT, STATIC_VARIANTS } from "~/constants/dates";
import { DateOrNull, Range } from "~/declarations/filters";
import Popover from "~/node_modules/@material-ui/core/Popover";

import { WeekStartAwareDayjsUtils } from "./WeekStartAwareDayjsUtils";

export interface Props {
  initialDate?: string;
  initialRange?: Range;
  disableFuture?: boolean;
  onSubmit: (range: Range) => void;
  open: boolean;
  anchorEl: Element | null;
  onClose: () => void;
}

const CustomRangePicker: React.FC<Props> = ({
  initialDate,
  initialRange,
  disableFuture = false,
  onSubmit,
  open,
  anchorEl,
  onClose
}: Props): JSX.Element => {
  const [begin, setBegin] = React.useState<DateOrNull>(null);
  const [end, setEnd] = React.useState<DateOrNull>(null);
  const [currentFilter, setFilter] = React.useState("");
  const [currentMonth, setCurrentMonth] = React.useState<DayjsType>(dayjs());
  const [
    hoveredDay,
    setHoveredDay
  ] = React.useState<MaterialUiPickersDate | null>(null);

  React.useEffect(() => {
    const date = dayjs(initialDate);

    setCurrentMonth(date);
  }, [initialDate]);

  const [initialBegin, initialEnd] = initialRange ?? [null, null];

  React.useEffect(() => {
    setBegin(initialBegin ? dayjs(initialBegin) : null);
    setEnd(initialEnd ? dayjs(initialEnd) : null);
    !initialBegin && !initialEnd && setFilter("");
  }, [initialBegin, initialEnd]);

  const handleSubmit = (): void => {
    onSubmit([begin, end?.add(1, "day").subtract(1, "millisecond") ?? null]);
  };

  const handleSetCustomRange = (date: DayjsType): void => {
    const currentDay = dayjs();

    if (disableFuture && date.isAfter(currentDay)) return void 0;

    setFilter(STATIC_VARIANTS[0].label);

    if (begin && end) {
      setBegin(date);
      setEnd(null);
    } else if (!begin) {
      setBegin(date);
    } else if (!end) {
      if (date.isBefore(begin)) {
        setEnd(begin);
        setBegin(date);
      } else {
        setEnd(date);
      }
    }
  };

  const handleSetHover = (date: MaterialUiPickersDate) => (): void => {
    begin && !end && setHoveredDay(date);
  };

  const handleNextMonth = (): void => {
    const nextMonth = currentMonth.add(1, DATE_UNIT.month);
    setCurrentMonth(nextMonth);
  };

  const handlePrevMonth = (): void => {
    const prevMonth = currentMonth.subtract(1, DATE_UNIT.month);
    setCurrentMonth(prevMonth);
  };

  const handleSelectMonth = (date: MaterialUiPickersDate): void => {
    date && setCurrentMonth(dayjs(date));
  };

  const handleChangeRange = (range: DayjsType[], filter: string): void => {
    setBegin(range[0]);
    setEnd(range[1]);
    setCurrentMonth(range[1]);
    setFilter(filter);
    setHoveredDay(null);
  };

  const prevMonth = currentMonth.subtract(1, DATE_UNIT.month);
  const disableSubmit = Boolean(!begin || !end);

  return (
    <Popover
      open={open}
      anchorEl={anchorEl}
      onClose={onClose}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "center"
      }}
      transformOrigin={{
        vertical: "top",
        horizontal: "right"
      }}
    >
      <Wrapper>
        <MuiPickersUtilsProvider utils={WeekStartAwareDayjsUtils}>
          <Pickers>
            <div>
              <CustomRangePickerHeader
                end={end}
                begin={begin}
                withActivationIndication
              />
              <DatePicker
                renderDay={(
                  date,
                  selectedDate,
                  dayInCurrentMonth
                ): JSX.Element => (
                  <CustomRangePickerDay
                    onClick={handleSetCustomRange}
                    onFocus={handleSetHover(date)}
                    date={date}
                    beginDate={begin}
                    endDate={end}
                    dayInCurrentMonth={dayInCurrentMonth}
                    hoveredDate={hoveredDay}
                    disableFuture={disableFuture}
                  />
                )}
                onSelectMonth={handleSelectMonth}
                onBack={handlePrevMonth}
                onNext={handleNextMonth}
                date={prevMonth}
                month={currentMonth}
                withControls
              />
              <DatePicker
                renderDay={(
                  date,
                  selectedDate,
                  dayInCurrentMonth
                ): JSX.Element => (
                  <CustomRangePickerDay
                    onClick={handleSetCustomRange}
                    onFocus={handleSetHover(date)}
                    date={date}
                    beginDate={begin}
                    endDate={end}
                    dayInCurrentMonth={dayInCurrentMonth}
                    hoveredDate={hoveredDay}
                    disableFuture={disableFuture}
                  />
                )}
                date={currentMonth}
                month={currentMonth}
              />
            </div>
            <CustomRangePickerVariants
              onChangeRange={handleChangeRange}
              currentFilter={currentFilter}
              onSubmit={handleSubmit}
              disabled={disableSubmit}
            />
          </Pickers>
        </MuiPickersUtilsProvider>
      </Wrapper>
    </Popover>
  );
};

export default CustomRangePicker;

const Wrapper = styled.div`
  border: 1px solid ${({ theme }): string => theme.palette.grey[400]};
  height: 470px;
  max-width: 425px;
`;

const Pickers = styled.div`
  display: flex;

  > div:first-of-type {
    flex: 1;
  }
`;
