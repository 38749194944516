import Button from "@material-ui/core/Button";
import { Dayjs as DayjsType } from "dayjs";
import * as React from "react";

import CustomRangePickerDynamicVariants from "~/components/core/datepickers/CustomRangePicker/CustomRangePickerDynamicVariants";
import CustomRangePickerStaticVariants from "~/components/core/datepickers/CustomRangePicker/CustomRangePickerStaticVariants";
import styled from "~/components/core/styled";
import { CUSTOM_PERIOD, PERIOD } from "~/constants/dates";
import { RANGE_GETTERS } from "~/constants/filters";

export interface Props {
  onChangeRange: (range: DayjsType[], filter: string) => void;
  currentFilter: string;
  onSubmit: () => void;
  disabled: boolean;
}

const CustomRangePickerVariants: React.FC<Props> = ({
  onChangeRange,
  currentFilter,
  onSubmit,
  disabled
}: Props): JSX.Element => {
  const handleSelectRange = React.useCallback(
    (
      period: PERIOD | CUSTOM_PERIOD,
      filter: string,
      active: boolean
    ) => (): void => {
      if (period !== PERIOD.custom) {
        const range = RANGE_GETTERS[period]();
        active && onChangeRange(range as DayjsType[], filter);
      }
    },
    [onChangeRange]
  );

  return (
    <Wrapper>
      <CustomRangePickerStaticVariants
        currentFilter={currentFilter}
        onSelectRange={handleSelectRange}
      />
      <CustomRangePickerDynamicVariants
        currentFilter={currentFilter}
        onInputRange={onChangeRange}
      />
      <StyledConfirmButton
        variant="contained"
        color="primary"
        onClick={onSubmit}
        disabled={disabled}
      >
        Apply Dates
      </StyledConfirmButton>
    </Wrapper>
  );
};

export default CustomRangePickerVariants;

const Wrapper = styled.div`
  border-left: 1px solid ${({ theme }): string => theme.palette.grey[400]};
  display: flex;
  flex-direction: column;
  width: 218px;
`;

const StyledConfirmButton = styled(Button)`
  margin: 5px 15px;
`;
