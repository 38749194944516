import TextField from "@material-ui/core/TextField";
import dayjs, { Dayjs as DayjsType } from "dayjs";
import * as React from "react";

import styled from "~/components/core/styled";
import { DATE_UNIT, DYNAMIC_VARIANTS } from "~/constants/dates";

interface Props {
  currentFilter: string;
  onInputRange: (range: DayjsType[], filter: string) => void;
}

const CustomRangePickerDynamicVariants: React.FC<Props> = ({
  onInputRange,
  currentFilter
}: Props): JSX.Element => {
  const [inputValue, setInputValue] = React.useState<number | null>(null);

  const handleSetInputValue = (filter: string, end: DayjsType) => (
    event: React.ChangeEvent<HTMLInputElement>
  ): void => {
    const diff = +event.currentTarget.value;
    const begin = end.subtract(diff, DATE_UNIT.day);
    const range = [begin, end];

    setInputValue(diff);
    onInputRange(range, filter);
  };

  return (
    <Wrapper>
      {DYNAMIC_VARIANTS.map(
        ({ label, offset, type }): JSX.Element => {
          const end = dayjs().subtract(offset, type);
          const selected = label === currentFilter;
          const value = selected ? inputValue : "";

          return (
            <TextFieldWrapper key={label}>
              <StyledTextField
                onChange={handleSetInputValue(label, end)}
                value={value}
                placeholder="30"
                variant="filled"
                type="number"
              />
              <Variant selected={selected}>{label}</Variant>
            </TextFieldWrapper>
          );
        }
      )}
    </Wrapper>
  );
};

export default CustomRangePickerDynamicVariants;

interface VariantProps {
  selected?: boolean;
  active?: boolean;
}

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: 20px 15px 0 15px;
`;

const TextFieldWrapper = styled.div`
  align-items: center;
  display: flex;
  margin-bottom: 20px;
`;

const StyledTextField = styled(TextField)<{}>`
  margin-right: 8px;
  width: 40px;

  .MuiInputBase-root {
    background-color: ${({ theme }): string => theme.palette.grey[200]};
    border-radius: 0;
  }

  input {
    font-size: 14px;
    padding: 5px 8px;
    text-align: center;
  }
`;

const Variant = styled.span<VariantProps>`
  color: ${({ theme, selected }): string =>
    selected ? theme.palette.common.black : theme.palette.grey[600]};
  cursor: ${({ active }): string => (active ? "pointer" : "default")};
  font-size: 14px;

  &:hover {
    color: ${({ theme }): string => theme.palette.primary.main};
  }
`;
