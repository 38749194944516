import { MaterialUiPickersDate } from "@material-ui/pickers/typings/date";
import dayjs from "dayjs";

import { DATE_UNIT } from "~/constants/dates";

interface UseDatePickerRangeProps {
  date: MaterialUiPickersDate;
  beginDate: MaterialUiPickersDate;
  endDate: MaterialUiPickersDate;
  hoveredDate: MaterialUiPickersDate;
}

interface UseDatePickerRangeState {
  frontier: boolean;
  inHoveredRange: boolean;
  inSelectedRange: boolean;
  current: boolean;
}

export function useDatePickerRange({
  date,
  beginDate,
  endDate,
  hoveredDate
}: UseDatePickerRangeProps): UseDatePickerRangeState {
  let inSelectedRange = false;
  let inHoveredRange = false;
  let frontier = false;

  if (date && beginDate && endDate) {
    inSelectedRange = date.isAfter(beginDate) && date.isBefore(endDate);
    frontier =
      date.isSame(beginDate, DATE_UNIT.day) ||
      date.isSame(endDate, DATE_UNIT.day);
  } else if (date && beginDate && hoveredDate) {
    inHoveredRange =
      (date.isAfter(beginDate) && date.isBefore(hoveredDate)) ||
      (date.isAfter(hoveredDate) && date.isBefore(beginDate));
    frontier =
      date.isSame(beginDate, DATE_UNIT.day) ||
      date.isSame(hoveredDate, DATE_UNIT.day);
  }

  const current =
    (!beginDate && !endDate && date?.isSame(dayjs(), DATE_UNIT.day)) || false;

  return {
    frontier,
    inHoveredRange,
    inSelectedRange,
    current
  };
}
