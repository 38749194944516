import IconButton from "@material-ui/core/IconButton";
import { ChevronLeft, ChevronRight, ExpandMore } from "@material-ui/icons";
import { Dayjs as DayjsType } from "dayjs";
import * as React from "react";

import styled from "~/components/core/styled";
import { MONTH_FORMAT } from "~/constants/dates";

export interface Props {
  month: DayjsType;
  onNext?: () => void;
  onBack?: () => void;
  onSwitch?: () => void;
  withControls?: boolean;
}

const CustomRangePickerToolbar: React.FC<Props> = ({
  onBack,
  onNext,
  onSwitch,
  month,
  withControls
}: Props): JSX.Element => {
  const formattedMonth = month.format(MONTH_FORMAT);

  return (
    <Wrapper>
      <YearControl>
        {formattedMonth}
        {withControls && (
          <IconButton onClick={onSwitch} size="small">
            <ExpandMore />
          </IconButton>
        )}
      </YearControl>
      {withControls && (
        <div>
          <IconButton onClick={onBack} size="small">
            <ChevronLeft />
          </IconButton>
          <IconButton onClick={onNext} size="small">
            <ChevronRight />
          </IconButton>
        </div>
      )}
    </Wrapper>
  );
};

export default CustomRangePickerToolbar;

const Wrapper = styled.div`
  align-items: center;
  display: flex;
  font-size: 15px;
  height: 30px;
  justify-content: space-between;
  padding: 0 5px 0 15px;
`;

const YearControl = styled.div`
  align-items: center;
  display: flex;
`;
