import IconButton from "@material-ui/core/IconButton";
import { MaterialUiPickersDate } from "@material-ui/pickers/typings/date";
import dayjs, { Dayjs as DayjsType } from "dayjs";
import React from "react";

import { useDatePickerRange } from "~/components/core/datepickers/CustomRangePicker/useDatePickerRange";
import styled, { isPropValid } from "~/components/core/styled";
import { DAY_FORMAT } from "~/constants/dates";

const FADED = "faded";
const CURRENT = "current";
const ACTIVE = "active";
const FRONTIER = "frontier";
const SELECTED = "selected";

export interface Props {
  onClick: (date: DayjsType) => void;
  onFocus: () => void;
  date: MaterialUiPickersDate;
  beginDate: MaterialUiPickersDate;
  endDate: MaterialUiPickersDate;
  hoveredDate: MaterialUiPickersDate;
  dayInCurrentMonth: boolean;
  disableFuture?: boolean;
}

const CustomRangePickerDay: React.FC<Props> = ({
  date,
  dayInCurrentMonth,
  onClick,
  onFocus,
  beginDate,
  endDate,
  hoveredDate,
  disableFuture
}: Props): JSX.Element | null => {
  const {
    frontier,
    inHoveredRange,
    inSelectedRange,
    current
  } = useDatePickerRange({
    date,
    beginDate,
    endDate,
    hoveredDate
  });

  if (!date) return null;

  const handleSelectDay = (): void => onClick(date);

  const futureDay = disableFuture && date.isAfter(dayjs());
  const selected = (inSelectedRange || inHoveredRange) && !futureDay;
  const formattedDay = dayjs(date).format(DAY_FORMAT);

  return (
    <Wrapper
      current={current ? CURRENT : ""}
      selected={selected ? SELECTED : ""}
      faded={selected && !dayInCurrentMonth ? FADED : ""}
      active={dayInCurrentMonth && !futureDay ? ACTIVE : ""}
      frontier={frontier && dayInCurrentMonth ? FRONTIER : ""}
      onClick={handleSelectDay}
      onMouseOver={onFocus}
      disableRipple
    >
      <span>{formattedDay}</span>
    </Wrapper>
  );
};

export default CustomRangePickerDay;

interface CustomDayWrapperProps {
  selected: string;
  active: string;
  faded: string;
  frontier: string;
  current: string;
}

type StyleType = undefined | false | string;

const Wrapper = styled(IconButton, { shouldForwardProp: isPropValid })<
  CustomDayWrapperProps
>`
  background-color: ${({
    theme,
    selected,
    frontier,
    faded,
    current,
    active
  }): StyleType => {
    if (active && current) return theme.palette.data.turquoise[300];
    if (faded) return theme.palette.grey[200];
    if (frontier) return theme.palette.data.turquoise[900];
    if (selected) return theme.palette.data.turquoise[300];
  }};
  border-radius: 0;
  color: ${({ theme, frontier, active, current }): string => {
    if (active && current) return theme.palette.common.black;
    if (!active) return theme.palette.grey[400];
    if (frontier) return theme.palette.common.white;
    return theme.palette.common.black;
  }};
  color: inherit;
  font-size: 0.75rem;
  height: 24px;
  margin: 1px;
  padding: 0;
  width: 24px;

  &:hover {
    background-color: ${({ theme }): string =>
      theme.palette.data.turquoise[300]};
    color: ${({ theme }): string => theme.palette.common.black};
  }
`;
