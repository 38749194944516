import * as React from "react";

import styled from "~/components/core/styled";
import { CUSTOM_PERIOD, PERIOD, STATIC_VARIANTS } from "~/constants/dates";

import { WithPeriodTitleTooltip } from "../../FilterToolbar/FilterToolbar";

interface Props {
  currentFilter: string;
  onSelectRange: (
    period: PERIOD | CUSTOM_PERIOD,
    filter: string,
    active: boolean
  ) => (event: React.MouseEvent<HTMLSpanElement, MouseEvent>) => void;
}

const CustomRangePickerStaticVariants: React.FC<Props> = ({
  currentFilter,
  onSelectRange
}: Props): JSX.Element => {
  return (
    <Wrapper>
      {STATIC_VARIANTS.map(({ label, period, active }) => {
        const selected = label === currentFilter;
        return (
          <WithPeriodTitleTooltip period={period} key={label}>
            <Variant
              active={active}
              selected={selected}
              onClick={onSelectRange(period, label, active)}
            >
              {label}
            </Variant>
          </WithPeriodTitleTooltip>
        );
      })}
    </Wrapper>
  );
};

export default CustomRangePickerStaticVariants;

interface VariantProps {
  selected?: boolean;
  active?: boolean;
}

type StyleType = undefined | false | string;

const Wrapper = styled.div`
  border-bottom: 1px solid ${({ theme }): string => theme.palette.grey[400]};
  display: flex;
  flex-direction: column;
  padding: 18px 15px 5px 25px;
`;

const Variant = styled.span<VariantProps>`
  color: ${({ theme, selected }): string =>
    selected ? theme.palette.common.black : theme.palette.grey[600]};
  cursor: ${({ active }): string => (active ? "pointer" : "default")};
  font-size: 14px;
  margin-bottom: 18px;

  &:hover {
    color: ${({ theme, active }): StyleType =>
      active && theme.palette.primary.main};
  }
`;
