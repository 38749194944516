import { MaterialUiPickersDate } from "@material-ui/pickers/typings/date";
import dayjs, { Dayjs as DayjsType } from "dayjs";
import * as React from "react";

import CustomRangePickerToolbar from "~/components/core/datepickers/CustomRangePicker/CustomRangePickerToolbar";
import DatePickerContent from "~/components/core/datepickers/DatePicker/DatePickerContent";
import { DATE_UNIT } from "~/constants/dates";
import { RenderDayFunc } from "~/declarations/mui-datepickers";

export interface Props {
  renderDay: RenderDayFunc;
  date: DayjsType;
  month: DayjsType;
  withControls?: boolean;
  onNext?: () => void;
  onBack?: () => void;
  onSelectMonth?: (date: MaterialUiPickersDate) => void;
}

const DatePicker: React.FC<Props> = ({
  date,
  month,
  withControls,
  renderDay,
  onNext,
  onBack,
  onSelectMonth
}: Props): JSX.Element => {
  const [currentView, setCurrentView] = React.useState(DATE_UNIT.day);
  const [newYear, setNewYear] = React.useState<DayjsType | null>(null);

  const handleSelectNewDate = (): void => {
    setCurrentView(DATE_UNIT.year);
  };

  const handleSelectYear = (date: MaterialUiPickersDate): void => {
    setCurrentView(DATE_UNIT.month);
    setNewYear(date && dayjs(date));
  };

  const handleSelectMonth = (date: MaterialUiPickersDate): void => {
    setCurrentView(DATE_UNIT.day);
    const monthCount = date?.get(DATE_UNIT.month);
    const newDate = newYear?.set(DATE_UNIT.month, monthCount || 0);
    newDate && onSelectMonth?.(newDate);
  };

  return (
    <div>
      <CustomRangePickerToolbar
        withControls={withControls}
        onSwitch={handleSelectNewDate}
        onNext={onNext}
        onBack={onBack}
        month={date}
      />
      <DatePickerContent
        date={date}
        month={month}
        renderDay={renderDay}
        currentView={currentView}
        onSelectYear={handleSelectYear}
        onSelectMonth={handleSelectMonth}
      />
    </div>
  );
};

export default DatePicker;
