import { StaticDatePicker } from "@material-ui/pickers";
import { MaterialUiPickersDate } from "@material-ui/pickers/typings/date";
import { Dayjs as DayjsType } from "dayjs";
import * as React from "react";

import styled from "~/components/core/styled";
import { DATE_UNIT } from "~/constants/dates";

export interface Props {
  onChange: (date: MaterialUiPickersDate) => void;
  initialValue: DayjsType;
}

const CustomRangePickerYear: React.FC<Props> = ({
  onChange,
  initialValue
}: Props): JSX.Element => {
  return (
    <Wrapper>
      <StaticDatePicker
        value={initialValue || null}
        onChange={onChange}
        views={[DATE_UNIT.year]}
      />
    </Wrapper>
  );
};

export default CustomRangePickerYear;

const Wrapper = styled.div`
  margin-bottom: 24px;
  min-height: 160px;
  width: 205px;

  > div {
    min-width: 190px;
  }

  .MuiPickersToolbar-toolbar {
    display: none;
  }

  .MuiPickersBasePicker-pickerView {
    height: 145px;
    width: 190px;

    .MuiPickersCalendarView-viewTransitionContainer {
      overflow-x: hidden;
      scrollbar-color: darkgrey lightgrey;
      scrollbar-width: thin;

      &::-webkit-scrollbar {
        width: 3px;
      }

      &::-webkit-scrollbar-track {
        background-color: lightgrey;
        border-radius: 3px;
      }

      &::-webkit-scrollbar-thumb {
        background-color: darkgrey;
        border-radius: 3px;
        outline: 1px solid slategrey;
      }

      .MuiPickersYearSelection-container {
        width: 190px;

        .MuiPickersYear-yearContainer {
          width: 55px;

          .MuiPickersYear-yearLabel {
            font-size: 14px;
            height: 30px;
            width: 55px;
          }
        }
      }
    }
  }
`;
